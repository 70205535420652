<template>
  <div>
    <ui-header :heading="$tc('models.my-courses', 2)"> </ui-header>
    <el-spinner v-if="loading" stroke-color="red"></el-spinner>
    <template v-if="myCourses.length <= 0 && !loading">
      <div v-if="isUiBEmployee">
        <p>{{$t('misc.no-assigned-courses-uib')}}</p>
        <ui-link class="mt-2" type="danger" @click="openConnectionsModal"
                 v-if="possibleDepartmentUserConnections"
        >
          {{
            `${$t('actions.change')} ${$tc('models.department', 2)}`
          }}
        </ui-link>

        <!-- Connection modal for selecting affiliation -->
        <connection-modal
            :show="connectionsModal"
            :text="$t('actions.confirm_delete', { model: $tc('misc.connections') })"
            :confirmText="$t('actions.change')"
            :companies="possibleDepartmentUserConnections"
            :permissionValidator="userHasPermission"
            @confirm="changeConnections"
            @cancel="connectionsModal = false"
        >
        </connection-modal>
      </div>
      <p v-if="!isUiBEmployee">{{$t('misc.no-assigned-courses')}}</p>
    </template>
    <template v-if="myCourses.length > 0">
      <section v-if="myWindow.width > 1023">
        <my-course-card
            v-for="course in myCourses"
            :key="`course_${course.id}`"
            :course="course"
        >
        </my-course-card>
      </section>

      <section v-if="myWindow.width <= 1023">
        <my-course-card-small
            v-for="course in myCourses"
            :key="`course_${course.id}`"
            :course="course"
        >
        </my-course-card-small>
      </section>
    </template>
  </div>
</template>

<script>
import UiLink from '@/components/ui/UiLink';
import UiHeader from '@/components/ui/UiHeader';
import MyCourseCard from '../components/MyCourseCard';
import MyCourseCardSmall from '../components/MyCourseCardSmall';
import { getMyCourses } from '../api';
import { getProfile } from '../../profile/api';
import jwtDecode from 'jwt-decode';
import { mapGetters } from 'vuex';
import { userHasPermission, getAllUserPermissions } from '@/services/permissions';
import { updateUserDepartmentConnections, getPossibleDepartmentUserConnections } from "../../profile/api";
import ConnectionModal from "../../profile/components/ConnectionModal";

export default {

  data() {
    return {
      myCourses: [],
      loading: true,
      myWindow: {
        width: 600,
        height: 800
      },
      my_course: this.$t('models.my-courses'),
      // UiB Related
      isUiBEmployee: false,
      connectionsModal: false,
      possibleDepartmentUserConnections: null,
    };
  },

  components: {
    UiLink,
    UiHeader,
    MyCourseCard,
    MyCourseCardSmall,
    ConnectionModal
  },

  computed: {

    ...mapGetters('oidcStore', ['oidcAccessToken']),

    //...mapGetters("languageStore", ["getSelectedLanaguage"]),
  },

  methods: {
    async getMyCourses() {
      this.loading = true;
      const courses = await getMyCourses();
      if (courses) this.myCourses = courses;
      else this.myCourses = [];
      this.loading = false;
    },

    async validateMyProfile() {
      try {
        const profile = await getProfile();

        var cookie = this.getCookie("new_user")

        if (!profile.firstName || !profile.lastName || cookie == "true") {
           var params = {
            forced: true,
          };

          if (cookie == "true") {
            params.new_user = true;
          }

          this.$router.push({
            name: 'profile-edit-forced',
            params: params
          });
        }
         
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
    },

    handleResize() {
      this.myWindow.width = window.innerWidth;
      this.myWindow.height = window.innerHeight;
    },

    isStudent() {
      const roles = jwtDecode(this.oidcAccessToken).role;
      let hasStudentRole = false;
      if (typeof roles != 'string') {
        if (roles.includes('Student')) {
          hasStudentRole = true;
        }
      } else {
        if (roles === 'Student') {
          hasStudentRole = true;
        }
      }
      return hasStudentRole;
    },

    /* Source: https://www.w3schools.com/js/js_cookies.asp */
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    userHasPermission() {
      return userHasPermission;
    },

    async getPossibleDepartmentUserConnections() {
      getPossibleDepartmentUserConnections().then(data => {
        // if no data is sent then user does not have permissions or is not under any company
        if (data.length) {
          this.possibleDepartmentUserConnections = data;
          if (this.$router.history.current.params.new_user == true) {
            this.connectionsModal = true;
          }
        }
      }).catch(e => {
        this.$message({ message: this.$t('errors.general'), type: 'error' })
      });
    },

    async openConnectionsModal() {
      this.connectionsModal = true;
    },

    async changeConnections(selectedDepartmentIds) {
      try {
        await updateUserDepartmentConnections(selectedDepartmentIds);
        document.cookie = 'new_user=; Max-Age=0; expires=Thu, 18 Dec 1971 12:00:00 UTC; domain=.tiv.no';

        this.$message({ message: this.$t('connections.change'), type: 'success' });
        this.connectionsModal = false;
        this.$router.push({ name: 'my-courses-index' });

      } catch (e) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      } finally {
        await this.getMyCourses()
      }
    }
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.my-courses", link: ""},
    ]);

    // Iterate over all user permissions and check if user is UiB employee
    getAllUserPermissions().forEach(permission => {
      if (permission.CompanyName.toLowerCase() === "uib"
          && permission.Permission === "change_connections"
          && permission.Scope === "company") {
        this.isUiBEmployee = true;
      }
    })


    this.getPossibleDepartmentUserConnections();
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getMyCourses();
    if (!this.isStudent()) {
      this.validateMyProfile();
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
