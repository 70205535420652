<template>
  <div class="course_card">
    <div class="upper_course_card">
    <section class="flex-column">
      <div name="course-name">
        <b>{{ course.title.filter(t => t.lang === $i18n.locale)[0].value + " - " + course.buildingName}}</b>
      </div>
      <div
        name="course-description"
        class="text-xs rich-text"
        v-html="
          course.description.filter(t => t.lang === $i18n.locale)[0].value
        "
      ></div>
    </section>
    </div>
    <div class="lower_course_card">
      <section class="progress_bar">
        <div>
          <ui-progress-bar :percentage="getProgress()"/>
        </div>
      </section>
    <section class="flex-column grow-up ml-2">

      <div v-if="course.validUntil">
        {{
          `${$t('misc.expiration')}: ${$d(
            new Date(course.validUntil),
            'short',
            $i18n.locale === 'no' ? 'no' : 'en'
          )}`
        }}
      </div>
      <div name="completed" v-if="course.completed">
        {{
          `${$t('misc.completed')}: ${$d(
            new Date(course.completed),
            'short',
            $i18n.locale === 'no' ? 'no' : 'en'
          )}`
        }}
      </div>
      <div name="completed" v-if="!course.completed">
        {{ $t(getNotCompleteLabel()) }}
      </div>
    </section>
      <ui-button
          v-if="showCompleteExam()"
          type="green"
          @click.prevent="navigateToExam($event)"
          class="finish_course_button"
      >{{ $t('models.exam') }}</ui-button
      >
      <ui-button @click="$router.push({ name: 'my-course-show', params: { mcid: course.id } }) " type="green" class="finish_course_button" v-if="!showCompleteExam()">
        {{$t("misc.complete_course")}}
      </ui-button>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton';
import UiProgressBar from "@/components/ui/UiProgressBar";
export default {
  props: {
    course: {
      type: Object,
      required: true
    }
  },

  components: {
    UiProgressBar,
    UiButton
  },

  methods: {
    getProgress() {
      let p = 0;
      if (this.course.completed) {
        p = 100;
      } else if (
        this.course.lessons &&
        this.course.lessons.length > 0 &&
        this.course.userLessons &&
        this.course.userLessons.length > 0
      ) {
        p = Math.floor(
          (this.course.userLessons.length / this.course.lessons.length) * 100
        );
      }
      return p;
    },

    getColor() {
      if (this.course.hasExam && !this.course.completed) {
        return 'warning';
      } else {
        return undefined;
      }
    },

    getNotCompleteLabel() {
      if (this.course.hasExam && this.showCompleteExam()) {
        return 'misc.exam_not_completed';
      } else {
        return 'misc.not-completed';
      }
    },

    showCompleteExam() {
      return (
        this.course.hasExam &&
        !this.course.completed &&
        this.course.lessons.length === this.course.userLessons.length
      );
    },

    navigateToExam(event) {
      event.stopPropagation();
      this.$router.push({
        name: 'my-course-exam',
        params: { mcid: this.course.id }
      });
    }
  }
};
</script>

<style scoped>

.flex-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  @apply p-4;
}

.grow-up {
  justify-content: end;
}

.flex-column div {
  @apply p-2;
}

.course_card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin-bottom: 1em;
}
.upper_course_card {
  display: flex;

}
.lower_course_card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress_bar {
  width: 40%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 1.5rem;
}
.finish_course_button {
  margin-right: 1em;
  width: 17em !important;
  height: 2.5em;
}
</style>
